import React, { useEffect, useState } from 'react'
import { globalCss } from '../../stitches.config'
import { reset } from 'stitches-reset'
import {
  Footer,
  Header,
  HeaderNavigator,
  HeaderNavigatorContainerSmall,
  mixpanelAnalyticsHelper,
  ScrollToTop
} from './components'
import { Box, Spinner } from '@sefar/design-system'
import {
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useSearchParams
} from 'react-router-dom'
import { NewsOverview } from './pages/news-overview/news-overview'
import { NewsDetails } from './pages/news-details/news-details'
import { ProfileSettings } from './pages/profile-settings/profile-settings'
import { Profile } from './pages/profile/profile'
import {
  isRegularUser,
  sendMessage,
  useAuthJWT,
  useHomePageBgImage,
  useIsSpaceEditor,
  useMe,
  User,
  USER_ROLES,
  usersCountriesMap,
  useTranslations,
  AUTH_PARAM_OAUTH_NAME,
  AUTH_PARAM_NAME
} from './api'
import { getCurrentTime } from './components'
import { useConfigStore } from './state/config'
import { Search } from './pages/search/search'
import { Home } from './pages/home/home'
import { MyContent } from './pages/my-content/my-content'
import { Login } from './pages/login/login'
import { motion } from 'framer-motion'
import { SpaceArticleDetails } from './pages/spaces/space-article-details'
import { Spaces } from './pages/spaces/spaces'
import { SubSpace } from './pages/spaces/subSpace'
import { IdeasOverview } from './pages/ideas-overview/ideas-overview'
import { IdeaDetails } from './pages/idea-details/idea-details'
import { Page404 } from './pages/404/404'
import { Notifications } from './pages/notifications/notifications'
import { startNotificationSession } from './api/notifications'
import { IdeaCreateConfirmation } from './pages/idea-details/confirmation'
import { IdeasDashboard } from './pages/ideas-dashboard'
import { AIAssistPage } from './pages/ai-assist'
import { PurchaseRequisitionsList } from './pages/purchase-requisitions'
import { CreatePurchaseRequisition } from './pages/purchase-requisitions/create'
import { CreatePurchaseRequisitionEdit } from './pages/purchase-requisitions/edit'
import { PurchaseRequisitionOverview } from './pages/purchase-requisitions/overview'
import { IdeaValidate } from './pages/idea-details/idea-validate'

import { sessionStorageKeys } from './constants'
import { initWebexAuthTokens } from './api/webex'
import { apiClient as purchaseRequsitionApi } from './pages/purchase-requisitions/api'
import { apiClient as aiApi } from './pages/ai-assist/api'

import './app.module.css'
import { useScrollDirection } from './hooks/useScrollDirection'

export const bottomMenuHeight = '70' // px

export const profilePageUrl = 'profile'
export const searchPageUrl = 'search'
export const newsPageUrl = 'news'
export const newsCreatePageUrl = `${newsPageUrl}/create`
export const myContentPageUrl = 'my-content'
export const spacesPageUrl = 'spaces'
export const spacesCreateArticlePageUrl = `${spacesPageUrl}/create`
export const subSpacePageUrl = 'subspace'
export const ideasPageUrl = 'ideas'
export const ideaCreatePageUrl = `${ideasPageUrl}/create`
export const ideaCreateConfirmationPageUrl = 'idea-create-confirmation'
export const AIAssistPageUrl = 'ai-assist'
export const purchaseRequisitionsPageUrl = 'purchase-requisitions'
export const createPurchaseRequisitionPageUrl = `${purchaseRequisitionsPageUrl}/create`
export const createPurchaseRequisitionEditPageUrl = `${purchaseRequisitionsPageUrl}/:id`
export const createPurchaseRequisitionReviewPageUrl = `${purchaseRequisitionsPageUrl}/:id/overview`

export type bgImageMetaDataType = {
  localtime: string
  location: string
  photographer: string
}

const injectGlobalStyles = globalCss({
  ...reset,
  '@import': [
    "url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap')"
  ],
  html: {
    scrollBehavior: 'smooth'
  },
  'body, body *': {
    boxSizing: 'border-box',
    fontFamily: 'Noto Sans, serif',
    font: 'Noto Sans, serif',
    '-webkit-font-smoothing': 'antialiased',
    '-moz-osx-font-smoothing': 'antialiased'
  },

  '*:after': {
    boxSizing: 'border-box',
    fontFamily: 'Noto Sans, serif',
    font: 'Noto Sans, serif'
  },
  '*:before': {
    boxSizing: 'border-box',
    fontFamily: 'Noto Sans, serif',
    font: 'Noto Sans, serif'
  },
  // https://stackoverflow.com/questions/2989263/disable-auto-zoom-in-input-text-tag-safari-on-iphone
  'input[type="color"], input[type="date"], input[type="datetime"], input[type="datetime-local"], input[type="email"], input[type="month"], input[type="number"], input[type="password"], input[type="search"], input[type="tel"], input[type="text"], input[type="time"], input[type="url"], input[type="week"], select:focus, textarea':
    {
      fontSize: '1rem'
    },

  '#root': {
    position: 'relative'
  }
})

injectGlobalStyles()

function AuthOutlet() {
  const [searchParams] = useSearchParams()
  const uid = searchParams?.get('uid')
  const csrf = searchParams?.get('csrf_token')
  const oauth_token = searchParams?.get('token')
  const {
    setMe,
    id,
    setPendingMessagesCount,
    notificationBus,
    setNotificationBus,
    setIsSpaceEditor,
    setAuth
  } = useConfigStore()
  const { me, isError, isLoading: userIsLoading } = useMe(uid)

  if (oauth_token || csrf) {
    if (oauth_token) {
      localStorage.setItem(AUTH_PARAM_OAUTH_NAME, oauth_token)
    }
    if (csrf) {
      // localStorage.setItem(AUTH_PARAM_NAME, csrf)
    }
    window.dispatchEvent(new Event('auth_token_change'))
  }

  const [pingTimer, setPingTimer] = useState<number>()
  const { isSpaceEditor, isLoading: spaceEditorLoading } = useIsSpaceEditor(
    me?.id
  )
  const { data: authData } = useAuthJWT()
  const isLoading = userIsLoading || spaceEditorLoading
  useEffect(() => {
    if (me?.id) {
      setMe(me)
      const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
      const userCountryCode =
        Object.entries(usersCountriesMap)
          .find(
            ([_, countryInfo]) =>
              countryInfo.options.includes(me.country) ||
              countryInfo.label === me.country
          )?.[0]
          ?.toUpperCase() ?? null

      mixpanelAnalyticsHelper().register({
        $city: me.location ?? null,
        $email: me.email,
        $name: `${me.firstName} ${me.lastName}`,
        $region: me.region ?? null,
        mp_country_code: userCountryCode
      })
      mixpanelAnalyticsHelper().identify(id)

      if (sessionStorage.getItem(sessionStorageKeys.LOGGED_IN_BY)) {
        mixpanelAnalyticsHelper().loggedInFrom()
        sessionStorage.removeItem(sessionStorageKeys.LOGGED_IN_BY)
      }

      mixpanelAnalyticsHelper().set({
        $city: me.location ?? null,
        $country_code: userCountryCode,
        $email: me.email,
        $name: `${me.firstName} ${me.lastName}`,
        $jobTitle: me.jobTitle,
        $region: me.region ?? null,
        $role: me.roles[0],
        $preferredLanguage: me.preferredLanguage,
        $Timezone: timeZone
      })

      if (me?.id && id && !notificationBus) {
        startNotificationSession(id, me, (ws) => {
          setNotificationBus(ws)
          ws.onmessage = (message) => {
            const parsedMessage = JSON.parse(message.data)
            switch (parsedMessage.id) {
              case 'registerResponse':
                setPingTimer(
                  window.setInterval(() => {
                    sendMessage(ws, { id: 'ping' })
                  }, 30000)
                )
                setPendingMessagesCount(
                  parsedMessage.session?.pendingMessagesCount
                )
                break
              case 'pendingCounterInfo':
                setPendingMessagesCount(parsedMessage.pendingCount)
                break
            }
          }
        })
      }
    }
    return () => {
      notificationBus?.close && notificationBus.close()
      setNotificationBus(undefined)
      window.clearInterval(pingTimer)
      setPingTimer(undefined)
    }
  }, [me?.id])

  useEffect(() => {
    if (!spaceEditorLoading && isSpaceEditor) {
      setIsSpaceEditor(isSpaceEditor)
    }
  }, [isSpaceEditor, spaceEditorLoading])

  useEffect(() => {
    if (authData?.success && authData?.data) {
      purchaseRequsitionApi.defaults.headers.common['Authorization'] =
        `Bearer ${authData?.data?.jwt}`
      aiApi.defaults.headers.common['Authorization'] =
        `Bearer ${authData?.data?.jwt}`
      setAuth(authData?.data)
    }
  }, [authData])

  return isLoading ? (
    <Box css={{ d: 'flex', alignItems: 'center', height: '100% ' }}>
      <Spinner />
    </Box>
  ) : isError || !me?.id ? (
    <Navigate to="/login" replace />
  ) : (
    <Outlet />
  )
}

export const RestrictedForRegularUserOutlet = ({ user }: { user: User }) => {
  const { isSpaceEditor } = useConfigStore()
  return isRegularUser(user) && !isSpaceEditor ? (
    <Navigate to="/" />
  ) : (
    <Outlet />
  )
}

const AnimatedBox = motion(Box)

export default function App() {
  const location = useLocation()
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const locationParts = location?.pathname?.split('/')?.slice(1)

  const homePage = location?.pathname === '/'
  const newsDetailsPage =
    locationParts?.[0] === 'news' && locationParts?.length > 1
  const spaceArticleDetailsPage =
    locationParts?.[0] === 'spaces' && locationParts?.length > 1

  const { contentLang, setContentLang, setTranslations, me } = useConfigStore()

  const { translations } = useTranslations(contentLang)

  const currentTime = getCurrentTime()
  const { bgImage } = useHomePageBgImage(me?.id ? currentTime : undefined)
  // TODO: This handler creates a lot of unnecessary rerenders. We need to move it deeper to component
  const [, scrollYPosition] = useScrollDirection()
  const isScrollPositionOnTop = scrollYPosition === 0

  const editingModeParam = searchParams.get('editMode')
  const [hideHeaderFooter, setHideHeaderFooter] = useState(false)
  const [hideFooter, setHideFooter] = useState(false)
  const [isFullHeightPage, setFullHeightPage] = useState(false)

  const webexAuthCode = searchParams.get('code')
  const webexReturnState = searchParams.get('state')

  // https://medium.com/quick-code/100vh-problem-with-ios-safari-92ab23c852a8
  function appHeight() {
    const doc = document.documentElement
    doc.style.setProperty('--app-height', `${window.innerHeight}px`)
  }

  useEffect(() => {
    if (webexAuthCode) {
      initWebexAuthTokens(webexAuthCode)
      searchParams.delete('code')
      if (webexReturnState) {
        try {
          const { returnUrl } = JSON.parse(webexReturnState)
          if (returnUrl) navigate(returnUrl)
        } catch (error) {
          console.error('Unable to navigate from webex return')
        }
      }
    }
  }, [webexAuthCode, webexReturnState])

  useEffect(() => {
    if (searchParams?.has('csrf_token')) {
      const newSearchParams = searchParams
      const staticSearchParams = ['csrf_token', 'token', 'uid', 'name']

      staticSearchParams.forEach((paramName) =>
        newSearchParams.delete(paramName)
      )

      Array.from(newSearchParams.keys())
        .filter((key) => key.startsWith('roles[') && key.endsWith(']'))
        .forEach((key) => newSearchParams.delete(key))

      setSearchParams(newSearchParams)
    }
  }, [searchParams])

  useEffect(() => {
    console.log(
      'Locaiton pathname',
      location?.pathname,
      purchaseRequisitionsPageUrl
    )
    if (location?.pathname.includes('ai-assist')) {
      setFullHeightPage(true)
    } else {
      setFullHeightPage(false)
    }

    if (
      editingModeParam === 'true' ||
      location?.pathname === '/login' ||
      location?.pathname?.includes(`/${createPurchaseRequisitionPageUrl}`) ||
      location?.pathname?.includes(`/${purchaseRequisitionsPageUrl}/`) ||
      // && location?.pathname !== `/${purchaseRequisitionsPageUrl}`
      (location?.pathname?.includes(`/${ideasPageUrl}/`) &&
        location?.pathname !== `/${ideasPageUrl}/dashboard`) ||
      location?.pathname.includes('/validate')
    ) {
      setHideHeaderFooter(true)
    } else if (location?.pathname?.includes(`/${AIAssistPageUrl}`)) {
      setHideFooter(true)
    } else if (
      location?.pathname?.includes(`/${purchaseRequisitionsPageUrl}`)
    ) {
      setHideHeaderFooter(false)
    } else if (location?.pathname === `/`) {
      setHideFooter(true)
    } else {
      setHideHeaderFooter(false)
      setHideFooter(false)
    }
  }, [editingModeParam, location?.pathname])

  useEffect(() => {
    window.addEventListener('resize', appHeight)
    appHeight()

    return () => {
      window.removeEventListener('resize', appHeight)
    }
  })

  useEffect(() => {
    setTranslations(translations)
  }, [translations])

  useEffect(() => {
    if (me?.preferredLanguage && me?.preferredLanguage !== contentLang) {
      setContentLang(me.preferredLanguage)
    }
  }, [me?.preferredLanguage])

  const isPurchaseRequisitionAvailable = [
    USER_ROLES.approver,
    USER_ROLES.purchaseRequisition
  ].some((role) => me?.roles.includes(role))

  return (
    <AnimatedBox
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
      id="main"
      css={{
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        height: isFullHeightPage ? '100%' : undefined,
        // backgroundImage: homePage
        //   ? `url(${bgImage?.thumbnails['2048px']})`
        //   : 'none',
        pb: `${hideHeaderFooter || hideFooter ? 0 : Number(bottomMenuHeight) + 16}px`,
        '@lg': {
          pb: 0
        },
        d: 'flex',
        flexDirection: 'column',
        justifyContent: hideFooter ? 'flex-start' : 'space-between',
        maskPosition: '50% 50%',
        minHeight: 'var(--app-height)',
        position: 'relative'
      }}
    >
      {!hideHeaderFooter && <Header />}

      <ScrollToTop>
        <Routes>
          <Route path={''} element={<AuthOutlet />}>
            <Route
              index
              element={
                <Home
                  bgImageMeta={bgImage?.metadata ? bgImage.metadata : null}
                />
              }
            />
            <Route path="profile-settings" element={<ProfileSettings />} />
            <Route path="notifications" element={<Notifications />} />
            <Route path="profile" element={<Profile />} />
            <Route path="profile/:userId" element={<Profile />} />
            <Route path={newsPageUrl} element={<NewsOverview />} />
            {me && (
              <Route
                path={newsCreatePageUrl}
                element={<RestrictedForRegularUserOutlet user={me} />}
              >
                <Route path="" element={<NewsDetails />} />
              </Route>
            )}
            <Route
              path={`${newsPageUrl}/:articleId`}
              element={<NewsDetails />}
            />
            <Route
              path={`${newsPageUrl}/:articleId/:lang`}
              element={<NewsDetails />}
            />
            <Route path={searchPageUrl} element={<Search />} />
            {me && (
              <Route
                path={myContentPageUrl}
                element={<RestrictedForRegularUserOutlet user={me} />}
              >
                <Route path="" element={<MyContent />} />
              </Route>
            )}
            <Route path={spacesPageUrl} element={<Spaces />} />
            <Route
              path={`${subSpacePageUrl}/:spaceId/:subspaceId`}
              element={<SubSpace />}
            />
            <Route
              path={spacesCreateArticlePageUrl}
              element={<SpaceArticleDetails />}
            />
            <Route
              path={`${spacesPageUrl}/create/:subspaceId`}
              element={<SpaceArticleDetails />}
            />
            <Route
              path={`${spacesPageUrl}/:articleId`}
              element={<SpaceArticleDetails />}
            />
            <Route
              path={`${spacesPageUrl}/:articleId/:lang`}
              element={<SpaceArticleDetails />}
            />
            <Route path={ideasPageUrl} element={<IdeasOverview />} />
            <Route
              path={`${ideasPageUrl}/dashboard`}
              element={<IdeasDashboard />}
            />
            <Route path={ideaCreatePageUrl} element={<IdeaDetails />} />
            <Route
              path={ideaCreateConfirmationPageUrl}
              element={<IdeaCreateConfirmation />}
            />
            <Route path={`${ideasPageUrl}/:ideaId`} element={<IdeaDetails />} />
            <Route
              path={`${ideasPageUrl}/:ideaId/validate`}
              element={<IdeaValidate />}
            />
            <Route
              path={`${ideasPageUrl}/:ideaId/:lang`}
              element={<IdeaDetails />}
            />
            {/* <Route path={AIAssistPageUrl} element={<AIAssistPage />} /> */}
            {/* <Route
              path={`${AIAssistPageUrl}/:threadId`}
              element={<AIAssistPage />}
            /> */}
            {isPurchaseRequisitionAvailable && (
              <>
                <Route
                  path={purchaseRequisitionsPageUrl}
                  element={<PurchaseRequisitionsList />}
                />
                <Route
                  path={createPurchaseRequisitionPageUrl}
                  element={<CreatePurchaseRequisition />}
                />
                <Route
                  path={createPurchaseRequisitionEditPageUrl}
                  element={<CreatePurchaseRequisitionEdit />}
                />
                <Route
                  path={createPurchaseRequisitionReviewPageUrl}
                  element={<PurchaseRequisitionOverview />}
                />
              </>
            )}
            <Route path="*" element={<Page404 />} />
          </Route>
          <Route path="login" element={<Login />} />
        </Routes>
        {location?.pathname !== '/login' && !me && (
          <Box css={{ d: 'flex', alignItems: 'center', height: '100% ' }}>
            <Spinner />
          </Box>
        )}
      </ScrollToTop>

      {!hideHeaderFooter && !hideFooter && (
        <>
          <Box css={{ flexShrink: 0 }}>
            <Footer variant="light" />
          </Box>
          <HeaderNavigatorContainerSmall
            css={{
              height: `${bottomMenuHeight}px`,
              zIndex: '$dropdown',
              backgroundColor: homePage
                ? isScrollPositionOnTop
                  ? 'rgba(255, 255, 255, 0.7)'
                  : 'rgba(93, 115, 133, 0.2)'
                : 'rgba(93, 115, 133, 0.2)'
            }}
          >
            <HeaderNavigator isHomePage={homePage} hideIcons />
          </HeaderNavigatorContainerSmall>
        </>
      )}
    </AnimatedBox>
  )
}
